import { Theme, makeStyles } from '@material-ui/core';

const styles = makeStyles((theme: Theme) => ({
    signupLink: {
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

export default styles;
