import React from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import { ROUTES } from 'config/Nav';
import BrandLogo from 'atoms/BrandLogo';
import { useRouter } from 'next/router';
import BaseLink from 'atoms/BaseLink';
import useStyles from 'theme/common';

const Index = (props: any) => {
    const router = useRouter();
    const classes = useStyles();

    return (
        <Box
            height={'100vh'}
            width={'100vw'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <BrandLogo />
            <Typography variant={'h5'} color={'secondary'}>
                coming soon
            </Typography>
            {/* <Box paddingTop={2}>
                <Button
                    color={'primary'}
                    variant={'contained'}
                    disableElevation
                    onClick={() => router.push('/shop')}
                >
                    Shop
                </Button>
            </Box> */}
            <Box marginTop={3}>
                <BaseLink href={ROUTES.SIGNUP.path} className={classes.signupLink}>
                    <Typography>
                        Sign up{' '}
                        <Typography
                            component={'span'}
                            variant={'body2'}
                            style={{ fontSize: '1.05rem' }}
                        >
                            here
                        </Typography>{' '}
                        to receive updates
                    </Typography>
                </BaseLink>
            </Box>
        </Box>
    );
};

export default Index;
